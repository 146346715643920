<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Bandeira')"
              :placeholder="$t('Bandeira')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="filters.type"
              type="vue-select"
              :label="$t('Tipo')"
              :placeholder="$t('Todos')"
              :options="cardTypeOptions()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="status"
              v-model="filters.status"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="onlineActive"
              v-model="filters.onlineActive"
              type="vue-select"
              :label="$t('Online')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="management-accounts-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('CARD.NAME'))"
        :fields="fields"
        :items="cards"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(image)="row">
          <b-img-lazy
            :src="row.item.image || noImage"
            rounded
            center
            width="165px"
            style="object-fit: contain; max-height: 150px"
          />
        </template>
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>
        <template #cell(onlineActive)="row">
          <e-status-badge :status="row.item.onlineActive" />
        </template>
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="true"
            :show-delete="false"
            @update="updateCard(row)"
          />
          <!-- @delete="inactiveCard(row.item)" -->
          <!-- TODO inativar cartão -->
        </template>
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="management-accounts-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      :main-tooltip="$t('Adicionar novo cartão')"
      @click="createCard"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable, BImgLazy } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { cardDomain, statusTypes } from '@/mixins'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'

export default {
  components: {
    BRow,
    BCol,
    BImgLazy,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    EGridActions,
  },

  mixins: [statusTypes, cardDomain],

  data() {
    return {
      fetching: false,
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
    }
  },

  computed: {
    ...mapState('pages/settings/financial/card', {
      cards: 'cards',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Bandeira'),
          key: 'name',
          class: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Tipo'),
          key: 'type',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.cardTypeLabel[val] || '',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Imagem'),
          key: 'image',
          class: 'text-center',
          thStyle: { width: '200px' },
        },
        {
          label: this.$t('Online'),
          key: 'onlineActive',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/settings/financial/card/cardMaintain', {
      stCleanStateOfMaintain: 'cleanState',
    }),
    ...mapActions('pages/settings/financial/card', [
      'fetchCards',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCards()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    updateCard(row) {
      const { id } = row.item
      this.$router.push({ name: 'card-maintain', params: { id } })
    },

    createCard() {
      this.stCleanStateOfMaintain()
      this.$router.push({ name: 'card-add' })
    },

    async inactiveCard() {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          // await this.$http.delete(`/api/product-groups/${group.id}`)
          this.getData()
          this.showSuccess({ message: this.$t('Cartão inativado com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
